<template>
    <div class="container">
        <router-view/>
    </div>
    <the-footer/>
</template>

<script>
import "./assets/style.css"
import TopicContainer from "@/components/TopicContainer.vue";
import ContactBox from "@/components/ContactBox.vue";
import TextBox from "@/components/TextBox.vue";
import TheFooter from "@/components/TheFooter.vue";

export default {
    components: {TheFooter, TextBox, ContactBox, TopicContainer}
}
</script>

<style>

</style>
