import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import( '@/views/HomeView.vue')
    },
    {
        path: '/legal-notice',
        name: 'legalNotice',
        meta: {title: "Impressum"},
        component: () => import( '@/views/LegalNotice.vue')
    },
    {
        path: '/data-privacy',
        name: 'dataPrivacy',
        meta: {title: "Datenschutzerklärung"},
        component: () => import( '@/views/DataPrivacy.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
